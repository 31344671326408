import React from "react";
import styled from "@emotion/styled";

const TitpFAQ = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
  h1 {
    font-weight: bold;
    font-size: 28px;
  }
  .subtitle {
    color: #828282;
    font-size: 14px;
    margin: 10px 0;
  }
  .border {
    width: 100%;
    border-top: 1px solid #dddddd;
  }
`;

export default TitpFAQ;
