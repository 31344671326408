import React from "react";
import styled from "@emotion/styled";

const JobTable = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  h2 {
    font-weight: bold;
    font-size: 24px;
  }
  table {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    width: 100%;
  }
`;

export default JobTable;
