import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import Spacer from "../components/layout/Spacer";

import TableRow from "../components/pages/Titp/TableRow";
import JobTable from "../components/pages/Titp/JobTable";
import MainWrapper from "../components/pages/Titp/MainWrapper";
import Footer from "../components/common/Footer";
import TitpFAQ from "../components/pages/Titp/TitpFAQ";
import FAQcontnts from "../components/pages/Titp/FAQcontents";
import TitpHeader from "../components/pages/Titp/TitpHeader";
import Helmet from "react-helmet";
import Contact from "./contactEng";

const TitpPage = () => {
  return (
    <Wrapper
      initial={{opacity: 0, y:50}}
      animate={{opacity: 1, y: 0}}
      transition={{duration: 1}}
      exit={{opacity: 0, y:50}}
    >
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap');
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Noto+Sans:wght@400;600;700&display=swap');
        </style>
      </Helmet>
      <TitpHeader
        bg_img={
          <StaticImage
            src="../images/titp_top.jpg"
            alt="titp header image"
            layout="fullWidth"
            style={{ height: "42.5vw", maxHeight: "612px" }}
          />
        }
      >
        <h1>TITP</h1>
        <div className="line"></div>
        <Spacer height="min(46px, 3.194vw)" />
		
        	<p>iTips Is Recognised As A Sending Organization By Govt.Of India For The TITP Program.</p>
			<p>	We Recruit Young Skilled People To Work In Japan</p>
		
      </TitpHeader>

      <Spacer height="min(5vw, 150px)" />

      <MainWrapper>
        <h1>TITP</h1>
        <div className="description">
			<ul>
				<li>iTips Pvt Ltd is a subsidiary company of iTips Inc. which is located in Japan.</li>
				
				<li>iTips Pvt Ltd is em-paneled by Govt.of India to conduct Technical Internship TrainingProgram (TITP), under the 
				<a href = "https://nsdcindia.org/home-titp"  target="_blank" rel="noopener noreferrer"> National Skill Development Corporation (NSDC)</a></li>
				
				<li>iTips takes the role of a Sending Organization (SO) by identifying, training and providing 
					Technical training and Language training for interested candidates to be recruited 
					for on-the-job training in Japan, in Japanese construction and manufacturing companies.
				</li>
			</ul>
		</div>

		<h1>How TITP works?</h1>
        <div className="description">
			<p>
			TITP, is a program commenced by Japan in 1993, 
			aims to contribute to developing countries by accepting people from these countries and transferring skills through On-the-Job-Training(‘OJT’). 
			TITP intends to empower Indian youth by providing them with skill development and career advancement opportunities.
			</p>
		</div>

		<h1>Key Features of TITP</h1>
        <div className="description">
			<ul>
				<li>Duration of Technical Intern Training: Trained candidates will be sent to Japan for undergoing a technical intern training for 3 – 5 years.</li>
				
				<li>Job Categories: 80 Job Categories and 142 Operations are eligible under TITP.</li>
				
				<li>
					Pre – Departure Training in India:Candidates are required to undergo training in Japanese language, 
					Japanese lifestyle orientation and culture along with relevant domain training by Sending Organization(SO).
				</li>

				<li>
					Return to home Country: After completion of the Technical Intern Training in Japan, 
					the Intern Trainee is expected to return to his/ her home country and utilize the skills that were acquired in Japan.
				</li>
			</ul>
		</div>

		<h1>Benefits</h1>
        <div className="description">
			<ul>
				<li>Higher financial benefits and better savings.</li>
				
				<li>Acquire new skills and techniques of international standards.</li>
				
				<li>Experience the working conditions in Japan</li>
			</ul>
		</div>

		<h1>Our Role</h1>
        <div className="description">
			<ul>
				<li>Selection of Supervising Organization.</li>
				
				<li>Enrolment of suitable candidates and completion of their Pre-Departure Training in India.</li>
				
				<li>Selection of candidates by Supervising Organization/ Implementing Organization for undergoing TITP in Japan.</li>

				<li>Completion of documentation for candidates’ entry in Japan.</li>

				<li>Coordination with Supervising Organization and Implementing Organization during Technical Intern Trainees’ stay in Japan.</li>

				<li>Provision of support to the Technical Intern Trainees to India post completion of training in Japan.</li>
			</ul>
		</div>

        <JobTable>
          <h2 id="technology">Job categories for which iTips provides training are:</h2>
          <table>
            <tbody>
              <TableRow
                job={"Construction"}
                sub={"Construction(22 job categories)"}
                content={
					`
					Well drilling
					Building sheet metal work
					Freezing and air conditioning apparatus installing
					Fixture making
					Carpentry
					Frameworking
					Reinforcing bar construction
					Scaffolding
					Building stone construction
					Tiling
					Tile roofing
					Plastering
					Plumbing
					Heat insulation
					Interior finishing
					Sash setting
					Waterproofing
					Concrete pressure feeding
					Well point construction
					Paper hanging
					Application of construction equipment
					Furnace installation
					`
				}
              />  
            </tbody>

            <tbody>
              <TableRow
                job={"Machinery and Metals"}
                sub={"Machinery and Metals(15 job categories)"}
                content={
					`
					Casting
					Forging
					Die casting
					Machining
					Metal press
					Iron work
					Factory sheet metal work
					Electroplating
					Aluminum anodizing
					Finishing
					Machine inspection
					Machine maintenance
					Electronic equipment assembling
					Electric equipment assembling
					Print wiring board manufacturing

					`
				}
              />  
            </tbody>


          </table>
        </JobTable>

        <h1>Why Choose Japan ?</h1>
        <div className="description">
			<ol>
				<li>The candidate can easily expect approximately <strong>Rs. 1.00 to 1.50 Lakh per month</strong> as wage.</li>
				
				<li>The basic living expenses in Japan includes – Accommodation, Transportation, Gas, Electricity, Water, Taxes, Insurance etc. approximately Rs. 50,000 per month.</li>
				
				<li>During the 3 years working opportunity in Japan, the candidate can save a minimum of <strong>Rs. 20 Lakhs in 3 years.</strong></li>

			</ol>
		</div>

		<h1>Fee structure of TITP</h1>
        <div className="description">
			<ol>
				<li>Program Registration Fee		₹15,000+ GST</li>
				
				<li>Pre – Departure Training Fee 	₹30,000+ GST - ₹90,000+ GST
					{'\n'}<span style={{fontSize: '0.6rem'}}>*Japanese Language Training, Japanese Culture, including Study Material.</span>
				</li>
				
				<li>TITP Processing Fee		₹1,00,000
				{'\n'}<span style={{fontSize: '0.6rem'}}>*Interview, Visa Processing Charges, Pre-departure assistance</span>
				</li>

			</ol>
			<br></br>
			Total [1]+[2]+[3] = ₹1,45,000 - ₹2,05,000
		</div>
        
      </MainWrapper>


      <TitpFAQ>
        <h1>FAQ</h1>
        <p className="subtitle"></p>

        <Spacer height={"20px"}></Spacer>

        <div className="border"></div>
        <FAQcontnts
          question="1. What does iTips Pvt. Ltd. offer?"
          answer="You will get TITP (Technical Intern Training Program) training, Japanese language corporate training and the opportunity to study and work in Japan."
        ></FAQcontnts>
        <div className="border"></div>

        <FAQcontnts
          question="2. What is the TITP program?"
          answer="TITP (Technical Intern Training Program) is a training program designed to develop the skills of students and mold them to make a great fit for the various employment opportunities across various sectors. Japanese language, culture and work-specific training are offered as per the requirements."
        ></FAQcontnts>

        <div className="border"></div>
        <FAQcontnts
          question="3. Who delivers the training and corporate programs offered by iTips?"
          answer="The TITP and corporate training are delivered by Japanese natives or minimum N2 trainers to develop a better and deep understanding of the requirements like language, culture and work to be performed."
        ></FAQcontnts>

        <div className="border"></div>
        <FAQcontnts
          question="4. Across which sectors and industries are the students placed?"
          answer="The organization places the students across construction and manufacturing sectors as per the requirements. It includes well drilling, building sheet metal work, machining, scaffolding, casting, forging."
        ></FAQcontnts>

        <div className="border"></div>
        <FAQcontnts
          question="5. What assistance is provided to the students, if needed?"
          answer="We are the Japanese company and our parent company is located in Japan. Therefore, complete assistance is provided to the students whenever needed. Upon the arrival of the students in Japan, they are provided with a 1-month advance training which trains them to open a bank account, make the resident card, teaching related to their work and advanced Japanese language."
        ></FAQcontnts>

		<div className="border"></div>
        <FAQcontnts
          question="6. What is the duration of the training or program?"
          answer="An intensive 4-6-months Japanese language, culture and work-specific training is imparted to the students before they could take up their work. The training could be further extended to 1-year depending upon requirements and needs."
        ></FAQcontnts>

		<div className="border"></div>
		<FAQcontnts
          question="7. What is the fees for TITP program?"
          answer="Total requirement fee is ₹1,45,000 - ₹2,05,000.
		  ~Below are the fee structure of TITP.
		  ~
		  ~[1] Program Registration Fee		₹15,000+ GST
		  ~[2] Pre – Departure Training Fee 	₹30,000+ GST - ₹90,000+ GST
		  ~*Japanese Language Training, Japanese Culture, including Study Material.
		  ~[3] TITP Processing Fee		₹1,00,000
		  ~*Interview, Visa Processing Charges, Pre-departure assistance
		  ~Total [1]+[2]+[3] = ₹1,45,000 - ₹2,05,000
		  "
        ></FAQcontnts>

        <div className="border"></div>
      </TitpFAQ>
      <Spacer height={"100px"}></Spacer>

	  <Contact />
              
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  .titp-body {
    width: 80%;
    margin: 0 auto;
  }
`;

export default TitpPage;
