import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

interface Props {
  job: string;
  sub: string;
  content: string;
}

const TableRow = (props: Props) => {
  return (
    <Wrapper>
      <tr>
        <td className="job">
          <div className="job-top">
            {props.job}
            <div className="link-sp">
              <StaticImage
                layout="fullWidth"
                src="../../../images/linkIcon.png"
                alt=""
                className="linkicon"
              />
            </div>
          </div>
          <p>{props.sub}</p>
        </td>
        <td className="table-content"　>{props.content}</td>
        
      </tr>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;

    tr {
      width: 100%;
      border: solid 1px #dddddd;
      display: flex;
    }
    td {
      padding: 20px;
      font-size: 14px;
    }
    .job {
      font-size: 16px;
      font-weight: bold;
      p {
        font-weight: normal;
        color: #828282;
        font-size: 14px;
        margin: 16px 0;
      }
    }
    .table-content {
      padding: 20px;
      white-space: pre;
    }
    .link-pc {
      display: none;
    }
    .link-sp {
      width: 6%;
      position: relative;
    }
    .linkicon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 16px;
      height: 16px;
    }
    table,
    tbody,
    tr,
    td,
    th {
      width: 100%; /* 幅を100%にする */
      display: block; /* ブロック要素にする */
    }
    .job-top {
      width: 100%;
      justify-content: space-between;
      display: flex;
    }
  }
  @media screen and (min-width: 768px) {
    width: 100%;
    display: flex;

    tr {
      width: 100%;
      border: solid 1px #dddddd;
      display: flex;
    }
    td {
      padding: 20px;
      font-size: 14px;
    }
    .job {
      width: 36%;
      font-size: 16px;
      font-weight: bold;
      p {
        font-weight: normal;
        color: #828282;
        font-size: 14px;
        margin: 16px 0;
      }
    }
    .table-content {
      white-space: pre-wrap;
      padding: 20px 0;
      width: 58%;
      white-space: pre-line;
    }
    .link-pc {
      width: 6%;
      position: relative;
    }
    .link-sp {
      display: none;
    }
    .linkicon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 16px;
      height: 16px;
    }
  }
`;

export default TableRow;
